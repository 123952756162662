$(document).offOn(GLOBALS.readyEvent, function () {

    var makeTypeAheadList = function (el) {
        console.log('in make ta list')
        var val = el.val();
        var list = el.attr('list')
        if (val.length >= 3) {
            $('datalist[data-id="'+list+'"]').attr('id',list)
        } else {
           $('#'+list).attr('data-id',list).removeAttr('id')
        }
    }

    $('input[list]').each(function () {
        makeTypeAheadList($(this))
    });

    $(document).offOn('keyup', 'input[list]', function () {
        makeTypeAheadList($(this))
    })

})