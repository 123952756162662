(function ($) {

    var activeSelect = null;

    var setMonth = function (selected) {
        if (!selected) {
            selected = activeSelect.find('.period-select-month .selected')
        }
        if (selected.attr('disabled') == 'disabled') {
            // selected was move to disabed item due to year change reselect not disabled item
            selected.removeClass('selected');
            var enabled = activeSelect.find('.period-select-month .period-selector:not([disabled="disabled"])');
            selected = enabled.last();
            selected.addClass('selected')
        }
        var month = selected.attr('data-value');
        var year = activeSelect.find('.selected-year').text();
        var periodId = year + month;
        activeSelect.trigger('period-selected', [periodId]);
    }

    var setYear = function (year) {
        var prev = activeSelect.find('.previous-year');
        var next = activeSelect.find('.next-year');
        var min = prev.attr('data-min');
        var max = next.attr('data-max');
        var minYear = min.substring(0, 4);
        var maxYear = max.substring(0, 4);
        activeSelect.find('.selected-year').html(year)
        prev.attr('data-value', parseInt(year) - 1);
        next.attr('data-value', parseInt(year) + 1);
        year <= minYear ? prev.removeClass('text-high').addClass('text-gray-300') : prev.addClass('text-high').removeClass('text-gray-300');
        year >= maxYear ? next.removeClass('text-high').addClass('text-gray-300') : next.addClass('text-high').removeClass('text-gray-300');
        var months = activeSelect.find('.period-selector');
        months.removeAttr('disabled');
        if (year == minYear) {
            var minMonth = min.substring(4, 6);
            months.filter(function () {
                return $(this).attr('data-value') < minMonth
            }).attr('disabled', 'disabled')
        } else if (year == maxYear) {
            var maxMonth = max.substring(4, 6);
            months.filter(function () {
                return $(this).attr('data-value') > maxMonth
            }).attr('disabled', 'disabled')
        }
        setMonth();
    }

    $(document).offOn('click', '.period-select .previous-year, .period-select .next-year', function () {
        var that = $(this);
        var active = that.hasClass('text-high');
        if ((active) || ($('#admin-entries-index').length > 0)) {
            activeSelect = that.closest('.period-select');
            var year = that.attr('data-value');
            setYear(year)
        }
        return false;
    }).offOn('click', '.period-select .period-selector:not([disabled="disabled"])', function () {
        var that = $(this);
        // if (that.attr('disabled') != 'disabled') {
        activeSelect = that.closest('.period-select')
        that.addClass('selected').siblings().removeClass('selected');
        setMonth(that);
        return false;
        //}
    })

})(jQuery);