$(document).offOn(GLOBALS.readyEvent,function() {

    var checkDate = function(el) {
        var that = $(el);
        var div = that.closest('div');
        var from = new Date(div.attr('data-window-start'));
        var upto = new Date(div.attr('data-window-end'))
        var date = new Date(el.value);
        if ((date >= from) && (date <= upto)) {
            div[0].className = '';
            div.attr('data-error-message','')
        } else {
            div[0].className = 'error border border-red-700';
            div.attr('data-error-message','not within reporting window')
        }
    }

    $('#new-event-form').submit(function() {
        var that = $(this);
        $.ajax({
            url     : that.attr('action'),
            type    : that.attr('method'),
            dataType: 'html',
            data    : that.serialize(),
            success : function( data ) {
                //$(data).insertBefore('#events-editable > .tbody.tr:first');
                var tr = that.closest('.tr')
                if (tr.parent().hasClass('thead')) {
                    var tbody = tr.parent().next();
                    tbody.append(data);
                } else {
                    tr.after(data)
                }
                // newEvent.after(data);
                // newEvent.hide();
            },
            error   : function( xhr, err ) {
                console.log('error', err)
            }
        });

        
        return false;
    });

    $('#events-editable').offOn('change', function(e) {
        if (e.target.name == 'event[start_date]') {
            checkDate(e.target);
        }
        var $f = $(e.target.form);
        $.ajax({
            url     :  $f.attr('action'),
            type    :  $f.attr('method'),
            dataType: 'html',
            data    : $f.serialize(),
            success : function( data ) {
                // console.log('received', data)
            },
            error   : function( xhr, err ) {
                console.log('error', err)
            }
        });
    });



});