$(document)
    .on(GLOBALS.readyEvent, function () {
        if ($('body.clear-turbolinks-cache').length > 0) {
            console.log('clearing turbolinks cache')
            Turbolinks.clearCache();
            // window.location.reload(true)
        }

        $('[data-role=tags]').each(function(){
            var that = $(this);
            var hints = that.attr('data-tag-hints').split(',');
            that.tagify({
                whitelist: hints,
                dropdown: {
                    enabled: 0
                }
            })
        });

    })
    .offOn("click", "[data-remote][data-update]", function () {
        var that = $(this);
        var els = $(that.attr('data-update'));
        var src = els.attr('data-src');
        if (src == that[0].href) {
            els.show().siblings().hide();
            console.log('saved a round trip to server')
            return false;
        }
    })
    .offOn("ajax:success", "[data-remote][data-update], [data-remote][data-replace]", function (event) {
        var xhr = event.detail[2];
        var that = $(this);
        var els;
        var replacing = that[0].hasAttribute('data-replace');
        var update = replacing ? that.attr('data-replace') : that.attr('data-update');
        if ((update.startsWith('#')) || (that.attr('data-closest') == 'false')) {
            els = $(update);
        } else {
            els = that.closest(update);
        }
        if (replacing) {
            els.replaceWith(xhr.responseText)
        } else {
            els.attr('data-src', xhr.responseURL);
            els.html(xhr.responseText)
            if (els.hasClass('tab')) {
                els.show().siblings().hide();
            }
        }
    })
    .offOn("ajax:success", "[data-remote][data-remove]", function (event) {
        var that = $(this);
        var els;
        if (that.attr('data-closest') == 'false') {
            els = $(that.attr('data-remove'));
        } else {
            els = that.closest(that.attr('data-remove'));
        }
        els.remove();
    })
    .offOn("ajax:success", "[data-remote][data-success-message]", function (event) {
        var that = $(this);
        var msg = that.attr('data-success-message')
        var loc = that.attr('data-success-message-location');
        if (loc) {
            var el = $(loc)
            el.find('.message').html(msg);
            el.show();
        } else {
            alert(msg);
        }
    })
    .offOn("ajax:success", "[data-remote][data-success-goto]", function (event) {
        var that = $(this);
        document.location = that.attr('data-success-goto');
    })
    .offOn('click', "[data-toggle]", function () {
        var that = $(this);
        var selector = that.attr('data-toggle');
        $(selector).toggleClass(that.attr('data-toggle-class'));
        return false;
    })
    .offOn('click', '[data-tab]', function () {
        var that = $(this);
        that.parent().parent().find('.selected').removeClass('selected');
        that.addClass('selected');
        var href = that.attr('href');
        if (href.startsWith('#')) {
            $(href).reveal().siblings().hide();
            //return false;
        }
    })
    .offOn('click', '[data-href] input, [data-href] select', function (e) {
        e.stopPropagation(); // it's a selection checkbox we don't want the event to bubble to open the row
    })
    .offOn('click', '[data-href]', function (e) {
        var target = $(e.target);
        if (target.closest('a,button,input').length > 0) return true;
        if ((target.prop('tagName') == 'LABEL') && (target.attr('for'))) return true;  //was a click on label will repropagate with checkbox
        console.log('event', e)
        var that = $(this);
        var href = that.attr('data-href');
        var ctrl = (event.ctrlKey || event.metaKey)
        if (ctrl) {
            var win = window.open(href, '_blank')
            win.focus();
        } else {
            var tb = that.closest('[data-turbolinks]');
            var useTurbolinks = tb.attr('data-turbolinks') == 'false' ? false : true;
            useTurbolinks ? Turbolinks.visit(href) : document.location = href;
        }
        return false;
    })
    .offOn('change', '[data-submit-on-change="true"]', function () {
        var that = $(this);
        var form = that.closest('form');
        form.find('.submit-button').click();
    })
    .offOn('paste', function (e) {
        var that = $(e.target);

        // Get pasted data via clipboard API
        var clipboardData = e.originalEvent.clipboardData || window.clipboardData;
        var pastedData = clipboardData.getData('Text');

        //check pasted data for tabs and/or carriage returns into one of our fields
        // else just let it go
        if (((pastedData.indexOf("\t") >= 0) || (pastedData.indexOf("\n") >= 0)) && (that.prop("nodeName") == 'INPUT')) {
            if (!(that.attr('disabled'))) {
                var rawData = pastedData.split("\n").map((i) => i.split("\t"));
                //Find all inputfields on page
                var enterableFields = $('input:visible:not([disabled])');
                // Find paste recipient in collection
                var from = enterableFields.index(that);
                // remove input fields prior to paste recipient
                enterableFields.splice(0, from);
                var startRect = that[0].getBoundingClientRect();
                // remove fields that are positioned left of paste recipient
                var pastableFields = enterableFields.filter(function (i, el) {
                    return el.getBoundingClientRect().left >= startRect.left
                });
                // for remaining fields put into a table based on height
                var rawFields = [];
                var currentRow = [];
                var currentTop = startRect.top;
                var maxWidth = 0;
                pastableFields.each(function () {
                    var myTop = this.getBoundingClientRect().top
                    if (myTop > currentTop) {
                        if (currentRow.length > maxWidth) maxWidth = currentRow.length;
                        rawFields.push(currentRow.slice(0))
                        currentRow = []
                        currentTop = myTop;
                    }
                    currentRow.push($(this))
                });
                if (currentRow.length > maxWidth) maxWidth = currentRow.length;
                rawFields.push(currentRow.slice(0));
                for (var i = 0; i < rawFields.length; i++) {
                    var line = rawData[i];
                    if (line) {
                        for (var j = 0; j < maxWidth; j++) {
                            var dest = rawFields[i][j];
                            if (dest) {
                                var type = dest.attr('type');
                                var val = rawData[i][j];
                                if (type == 'number') {
                                    val = val.replace(/[^\d\.-]/g, '')
                                    var step = dest.attr('step');
                                    if (step.indexOf('.') >= 0) {
                                        val = parseFloat(val)
                                    } else {
                                        val = parseInt(val)
                                    }
                                }
                                dest.val(val)
                            }
                        }
                    }
                }
            }
            e.stopPropagation();
            e.preventDefault();
        }
        console.log(e.target)
    });
/*
    .offOn('click','[data-show]',function(){
        var that = $(this);
        var el = $(that.attr('data-show'));
        el.show().siblings().hide();
        el.trigger('shown')
        console.log('done the showy bit');
        return false;
    })
    .offOn('shown','[data-src]',function() {
        var that = $(this)
        if (!that.html()) {
            var src = that.attr('data-src');
            var path = src.startsWith('/') ? src : document.location.pathname.split('/').slice(0, -1).join('/') + "/" + src;
            console.log('loading data-src',src, path);
        }
    })

*/