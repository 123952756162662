$(document).offOn(GLOBALS.readyEvent, function () {

    $(document)
        .offOn('period-selected', '#admin-entries-index, #admin-periods-new', function (event, periodId) {
            var newHref = "/admin/periods/" + periodId + "/entries";
            document.location = newHref
        })
        .offOn('click', '#admin-entries-index .period-select .period-selector[disabled="disabled"]', function () {
            var that = $(this);
            var activeSelect = that.closest('.period-select')
            var month = that.attr('data-value');
            var year = activeSelect.find('.selected-year').text();
            var periodId = year + month;
            var mmm = that.text().trim();
            if (confirm('Would you like to open the ' + mmm + ' ' + year + ' period?')) {
                document.location = '/admin/periods/new?period_id=' + periodId;
            }
        })

        .offOn('click', '[data-search]', function () {
            var that = $(this);
            $('.entries-selection-list a').removeClass('selected');
            that.addClass('selected');
            var query = that.attr('data-search');
            $('[data-search-model]').val(query).trigger('keyup');
            return false;
        })
        .offOn('keyup', '#admin-entries-index [data-search-model]', function () {
            $('.entries-selection-list a').removeClass('selected');
            var query = $(this).val();
            $('.entries-selection-list a[data-search="' + query + '"]').addClass('selected')
        })
        .offOn('change','#entry_property_id',function() {
            console.log('updating entry vals')
            var that = $(this)
            var selected = that.children("option:selected");
            var rating = parseFloat(selected.attr('data-rating'));
            var rooms = parseInt(selected.attr('data-rooms'));
            $('#entry_rating').val(rating);
            $('.star-rating[data-input="#entry_rating"]').starRating('setRating', rating)
            $('#entry_number_of_rooms').val(rooms);
            var el = $('#entry_room_night_capacity');
            // if (!parseInt(el.val())) {
                var days = parseInt(el.attr('data-days'));
                el.val(rooms * days);
            // }
        })

});