(function () {

    var sortCookieName = function (model) {
        return "HDNZ" +model + 'Sort';
    }

    $(document).offOn('click','[data-reset-sort]',function() {
        var that = $(this);
        var table = that.attr('data-reset-sort');
        var cookieName = sortCookieName(table);
        COOKIE.set(cookieName, '');
    })


    $(document).offOn('click', '[data-sort-column]', function () {
        var that = $(this);
        var col = that.attr('data-sort-column');
        var table = that.attr('data-sort-table');
        var update = that.attr('data-sort-update');
        var cookieName = sortCookieName(table);
        var cookie = COOKIE.get(cookieName);
        if (cookie == col) col += ' desc';
        COOKIE.set(cookieName, col);
        var url = new URL(window.location);
        // var tableReg = new RegExp(table,'i');
        if (!url.pathname.toLowerCase().endsWith(table.toLowerCase())) {
            url.searchParams.set('table', table);
        }
        var page = url.searchParams.get('page');
        if (page) {
            url.searchParams.set('page', '1');
            // we want to reset any query page to first page to display start of sort.
            history.pushState(null, null, url.href);
        }
        $.get(url.href, function (data) {
            $(update).html(data);
        });
        return false;
    });

})
();