$.fn.loadSrc = function (cb) {
    return this.each(function () {
        var that = $(this);
        var src = that.attr('data-src');
        var loading = that.attr('data-loading');
        var current = that.attr('data-loaded');
        if ((current != src) && (loading != src)) {
            that.attr('data-loading', src);
            that.load(src, function () {
                that.removeAttr('data-loading');
                that.attr('data-loaded', src);
                that.trigger('data-loaded');
                if (cb) cb();
            })
        } else {
            if (cb) cb();
        }
    })
}

$.fn.reveal = function (cb) {
    return this.each(function () {
        var that = $(this);
        that.show();
        that.loadSrc(function () {
             that.trigger('revealed');
            window.dispatchEvent(new Event('resize'));  //needed for charts
            if (cb) cb();
        });
    })
}

$.fn.dataObj = function () {
    var primitive = function (str) {
        switch (str) {
            case "true":
                return true;
            case "false":
                return false;
            case /[\d.-]+/:
                return parseFloat(str);
            default:
                return str;
        }
    }

    function camelCase(str) {
        var arr = str.split('_');
        var newStr = "";
        for (var i = 1; i < arr.length; i++) {
            newStr += arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
        }
        return arr[0] + newStr;
    }

    var that = this[0];
    var result = {};
    $.each(that.attributes, function () {
        var k = this.name
        if (k.startsWith('data-')) {
            var v = primitive(this.value);
            var parts = k.split('-');
            var src = result
            for (var i = 1; i < parts.length - 1; i++) {
                var key = camelCase(parts[i])
                if (!src[key]) src[key] = {}
                src = src[key]
            }
            src[camelCase(parts[parts.length - 1])] = v;
        }
    })
    return result;
}

$.fn.csv_download = function () {
    // assume set of table elements passed.  Convert to arrays and post json to server which will return same as a csv file
    var data = []
    this.each(function () {
        var dtable = []
        var that = $(this);
        that.find('tr').each(function () {
            var row = $(this);
            var drow = [];
            row.find('th,td').each(function () {
                var cell = $(this);
                drow.push(cell.text().trim())
            });
            dtable.push(drow)
        });
        data.push(dtable)
    });
    return data;
}

$.fn.offOn = function (events, selector, data, handler) {
    return this.off(events, selector, handler).on(events, selector, data, handler);
}