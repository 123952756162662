$(document).offOn(GLOBALS.readyEvent, function () {
    if ($('#report-index').length > 0) {

        var isOverview = function (str) {
            return str.match(/overview/) != null;
        }

        var isRegion = function (str) {
            return str.match(/region_id/) != null;
        }

        $(window).offOn('popstate', function (e) {
            console.log('got popstate event', e, e.target.location.href)
            var loc = new URL(document.location.href);
            var params = new URLSearchParams(loc.search);
            var id;
            if (isOverview(loc.search)) {
                id = params.get("overview")
                pane = $('#'+id)
            } else {
                var region = isRegion(loc.search);
                if (region) {
                    id = params.get("region_id")
                } else {
                    id = params.get("property_id")
                }
                var hash = loc.hash //.split('-')[0]
                var parts = hash.split('-')
                var pane = $(parts[0] + '-' + id) // hash has hash at start so matches id
            }

             pane.reveal(function() {
                 window.scrollTo(0, 0);
             }).siblings().hide();  // show this region or property subsection
            pane.parent().show().siblings().hide(); // show this tab
            pane.closest('.panel').show().siblings().hide(); // show region or poprety section and hide other
        });

        var loc = new URL(document.location.href);
        if (!loc.search) {
            var pel =  $('[data-property-id]');
            var pid = pel.attr('data-property-id');
            var ccount = parseInt(pel.attr('data-compset-count')) || 0;
            if ((pid) && (ccount)) {
                loc.search = "?property_id="+pid
            } else {
                loc.search = "?region_id=" + $('[data-region-id]').attr('data-region-id') // get first region id from list of regions
            }
        }

        if (!loc.hash) {
            loc.hash = '#' + (isRegion(loc.search) ? 'regional_overview' : 'compset_overview');
        }

        if (loc != document.location) {

            GLOBALS.pushUrl(loc.href);
        } else {
            window.dispatchEvent(new Event('popstate')); // make sure initial load occurs
        }

        $('[href="' + loc.hash + '"]').addClass('selected');
        $('[href="' + loc.search + '"]').addClass('selected');


    }

    $(document).offOn('click','.report-main a[href]',function(e) {
        var that = $(this);
        var href = that.attr('href')
        if (href.indexOf('#') >= 0) e.preventDefault();
    })
        .offOn('click', '.report-link', function () {
        var that = $(this);
        $('.report-link').removeClass('selected');
        that.addClass('selected')
        var loc = new URL(document.location.href);
        var regionBefore = isRegion(loc.search);
        loc.search = that.attr('href');
        var regionAfter = isRegion(loc.search);
        if (regionBefore != regionAfter) {
            loc.hash = regionAfter ? '#regional_overview' : '#compset_overview';
            $('.report-nav-link').removeClass('selected');
            $('[href="' + loc.hash + '"]').addClass('selected');
        }
        GLOBALS.pushUrl(loc.href);
        return false;
    }).offOn('click', '.report-nav-link', function (e) {
        var that = $(this);
        $('.report-nav-link').removeClass('selected');
        that.addClass('selected')
        var loc = new URL(document.location.href);
        loc.hash = that.attr('href')
        GLOBALS.pushUrl(loc.href);
        e.preventDefault();
        return false;
    })

        .offOn('revealed', '.content-page', function (e) {
            // make sure this isn't a bubbled event
            if (this == e.target) {
                var that = $(this);
                // find last selected tab if any
                var prev = $('.subsection-toggles [data-tab].selected');
                if (prev.closest('.content-page')[0] != that[0]) {
                    if (prev.length < 1) {
                        prev = that.find($(document.location.hash.split('-').slice(0, 3).join('-'))) //attempt to find hash element to handle reload /back
                    }
                    var idx = 0
                    if (prev.length > 0) {
                        idx = prev.parent().children().index(prev)
                    }
                    var pill = that.find('[data-tab]:eq(' + idx + ')')
                    if (pill.length > 0) {
                        prev.removeClass('selected');
                        setTimeout(function () {
                            (that.hasClass('regional_measures') || that.hasClass('regional_timeseries')) ? pill.click() : pill[0].click() // use a quirk of jquery to not update the hash if it's regional measures which has sibsections
                        }, 1);
                    }
                }
            }
        })

        .offOn('revealed', '.regional-subsection, .compset-subsection', function (e) {
            // make sure this isn't a bubbled event
            if (this == e.target) {
                var that = $(this);
                // find last selected tab if any
                var prev = $('.regional-measure-toggles [data-tab].selected');
                if (prev.closest('.regional-subsection')[0] != that[0]) {
                    if (prev.length < 1) {
                        prev = that.find($(document.location.hash)) //attempt to find hash element to handle reload /back
                    }
                    var idx = 0
                    if (prev.length > 0) {
                        idx = prev.parent().children().index(prev)
                    }
                    var pill = that.find('[data-tab]:eq(' + idx + ')')
                    if (pill.length > 0) {
                        prev.removeClass('selected');
                        setTimeout(function () {
                            pill[0].click()
                        }, 1);
                    }
                }
            }
        })

        .offOn('period-selected', '#report-index', function (event, periodId) {

            var that = $(this);
            var loc = new URL(document.location.href);
            loc.pathname = "/report/" + periodId + "/";
            var href = loc.href
            var tb = that.closest('[data-turbolinks]');
            var useTurbolinks = tb.attr('data-turbolinks') == 'false' ? false : true;
            useTurbolinks ? Turbolinks.visit(href) : document.location = href;
        });
});
