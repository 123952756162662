$(document).offOn(GLOBALS.readyEvent, function () {

    $(document)
        .offOn('click', '#admin-pending-index .expand-property, #admin-properties-index .expand-property', function () {
            var that = $(this);
            var propertyId = that.attr('data-property-id');
            var icon = that.find('i')
            var closed = icon.hasClass('ti-angle-left');
            var cols = $('#properties').find('tr').first().children().length;
            if (closed) {
                var rows  = $('#properties').find('.property-'+propertyId+'-user');
                if (rows.length < 1) {
                    var url = '/admin/properties/' + propertyId + '/users?cols='+cols
                    $.get(url, function(data) {
                        that.closest('tr').after(data);
                    })
                } else {
                    rows.show();
                }
                icon.attr('class','ti-angle-down')
            } else {
                var rows  = $('#properties').find('.property-'+propertyId+'-user');
                rows.hide();
                icon.attr('class','ti-angle-left')
            }
            return false;
        })



});