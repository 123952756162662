$(document).offOn(GLOBALS.readyEvent, function () {

        var prevLoc = new URL(document.location);

        var daysInPeriod = function () {
            return parseInt($('#days_in_period').val());
        }

        if ($('#periods-show, #entries-edit').length > 0) {
            window.addEventListener('popstate', function (e) {
                var loc = new URL(document.location.href)
                if (prevLoc.pathname != loc.pathname) {
                    $('#entry-form').load(loc.href, function () {
                        var hash = prevLoc.hash || '#property'
                        console.log('clicking', $('.entry-nav-link[href="' + hash + '"]'))
                        $('.entry-nav-link[href="' + hash + '"]').click();
                        setTotals();
                    })
                }
                prevLoc = loc;
            });
        }

        var isSegmented = function () {
            return true
            // return ($("#entry_property_id").attr('data-segmented') == 'true');
        }

        var setError = function (el, err) {
            var msg = el.attr('data-error-text')
            if (msg) err = msg;
            var loc = el.attr('data-error-location')
            if (!loc) loc = '#' + el.attr('id') + '-error';
            el.parent().addClass('field_with_errors');
            el.attr('data-prev-err', $('#' + el.attr('id') + '-error').html())
            $(loc).html(err).addClass('text-red-600')
            el.trigger('error-set', [err])
            return false
        }

        var unsetError = function (el) {
            el.parent().removeClass('field_with_errors');
            var loc = el.attr('data-error-location');
            if (!loc) loc = '#' + el.attr('id') + '-error';
            $(loc).html(el.attr('data-prev-err')).removeClass('text-red-600')
            el.attr('data-prev-err', '');
            el.trigger('error-unset')
            return true
        }

        var setSegmentedEntry = function () {
            if (isSegmented()) {
                $('.segmented').show();
                $('.not-segmented').hide();
            } else {
                $('.segmented').hide();
                $('.not-segmented').show();
            }
        }

        var sameNumber = function (x1, x2) {
            return Math.round(parseFloat(x1) * 100) == Math.round(parseFloat(x2) * 100)
        }

        var totalNightsOccupied = function () {
            var te = $('#entry_total_room_nights_occupied');
            // $('#entry_total_room_nights_occupied').val(te.val());
            if (isSegmented()) {
                var total = 0
                $('.nights-occupied-segment').each(function () {
                    var that = $(this)
                    total += parseInt(that.val()) || 0;
                });
                $('#rno-total').val(Math.round(total) || '')
                if (!sameNumber(te.val(), total)) {
                    //setError(te, 'Total nights occupied does not match the sum of the categories')
                } else {
                    unsetError($('#rno-total'))
                }
            } else {
                unsetError(te); // when property is changed make sure this is reset
            }
        }

        var totalRoomRevenue = function () {
            var te = $('#entry_total_room_revenue');
            // $('#entry_total_room_revenue').val(te.val())
            if (isSegmented()) {
                var total = 0
                $('.room-revenue-segment').each(function () {
                    var that = $(this)
                    total += parseFloat(that.val()) || 0;
                });
                $('#rev-total').val((Math.round(total * 100) / 100) || '')
                if (!sameNumber(te.val(), total)) {
                    // setError(te, 'Total room revenue does not match the sum of the categories')
                } else {
                    unsetError($('#rev-total'))
                }
            } else {
                unsetError(te); // when property is changed make sure this is reset
            }
        }

        var calcOccupancy = function () {
            var rooms = parseInt($('#entry_number_of_rooms').val());
            var occ = parseInt($('#entry_total_room_nights_occupied').val());
            if (rooms && occ) {
                var pancy = occ / (rooms * daysInPeriod());
                $('#calc-occupancy').val(Math.round(pancy * 100) + '%')
            }
        }


        var calcAdr = function () {
            var occ = parseInt($('#entry_total_room_nights_occupied').val());
            var rev = parseFloat($('#entry_total_room_revenue').val());
            if (occ & rev) {
                var adr = rev / occ
                $('#calc-adr').val('$' + Math.round(adr));
            }
        }

        var calcRevpar = function () {
            var rooms = parseInt($('#entry_number_of_rooms').val());
            var rev = parseInt($('#entry_total_room_revenue').val());
            if (rooms && rev) {
                var revpar = rev / (rooms * daysInPeriod());
                $('#calc-revpar').val(Math.round(revpar))
            }
        }

        var calcSegmentAdr = function (el, blankRevOk) {
            if (blankRevOk !== false) blankRevOk = true;
            console.log('calcing seg adr')
            var row = el.closest('tr')
            var occ = parseInt(row.find('.nights-occupied').val());
            var revStr = row.find('.room-revenue').val()
            var rev = parseFloat(revStr);
            if (occ && rev) {
                var adr = rev / occ
                var el = row.find('.segment-adr');
                el.val('$' + Math.round(adr));
            }
            var comments = row.find('.comments-segment');
            var revCheck = blankRevOk ? revStr != '' : true;
            if (!rev && occ > 0 && revCheck && comments.val() == '') {
                comments.parent().addClass('field_with_errors')
                comments.attr('placeholder', 'Required: please explain why there was no income')
            } else {
                comments.parent().removeClass('field_with_errors')
                comments.attr('placeholder', '')
            }
        }


        var calcGuestTotal = function () {
            var total = 0
            $('.guest').each(function () {
                total += parseInt($(this).val() || 0)
            })
            $('#guest_total').val(total || '');
            var te = $('#entry_total_room_nights_occupied');
            if (sameNumber(te.val(), total)) {
                unsetError($('#guest_total'))
            }
            /*
            if ((total) && (total != parseInt($('#guest-total-reference-value').text()))) {
                $('#guest-total-reference').show();
            } else {
                $('#guest-total-reference').hide();
            }

             */
        }

        var modifiedFromProperty = function (that) {
            var property = that.attr('data-property-value');
            var changed = property != that.val();
            var comment = that.parent().children().last();
            if (changed) {
                comment.show();
            } else {
                comment.hide();
            }
        }

        var setCapacity = function (force) {
            var cap = $('#entry_room_night_capacity')
            if ((force) || (!parseInt(cap.val()))) {
                cap.val(parseInt(cap.attr('data-days')) * parseInt($('#entry_number_of_rooms').val()))
            }
        }

        /*
        var copyValue = function(that) {
            var dest = $(that.attr('data-copy-value'));
            dest.val(that.val());
        }
         */

        $(document)
            .offOn('keyup', '.nights-occupied', totalNightsOccupied)
            .offOn('keyup', '#entry_total_room_nights_occupied', function () {
                $('.rno-reference').html($(this).val())
            })
            .offOn('keyup', '.room-revenue', totalRoomRevenue)
            .offOn('keyup', '#entry_total_room_revenue', function () {
                $('.rev-reference').html($(this).val())
            })
            .offOn('keyup', '#entry_number_of_rooms, #entry_total_room_nights_occupied', calcOccupancy)
            .offOn('keyup', '#entry_total_room_nights_occupied, #entry_total_room_revenue', calcAdr)
            .offOn('keyup', '#entry_number_of_rooms, #entry_total_room_revenue', calcRevpar)
            .offOn('keyup', '.calc-segment-adr', function () {
                calcSegmentAdr($(this));
            })
            .offOn('keyup', '.guest', calcGuestTotal)
            .offOn('change', '[data-property-value]', function () {
                modifiedFromProperty($(this))
            })
            .offOn('change', '#entry_number_of_rooms', function () {
                setCapacity(true)
            })
        /*
        .offOn('keyup', '[data-copy-value]', function () {
            copyValue($(this))
        })
         */

        var showStars = function () {
            $(".star-rating").each(function () {
                console.log('showing star rating')
                var that = $(this);
                var input = $(that.attr('data-input'));
                var emptyColor = that.hasClass('field-with-errors') ? '#F9AAA8' : 'lightgray';
                var activeColor = '#17abbd';
                var readOnly = input.attr('disabled');
                if (readOnly) {
                    var emptyColor = '#dddddd';
                    var activeColor = '#777777';
                }
                that.starRating({
                    starSize: 25,
                    disableAfterRate: false,
                    totalStars: 5,
                    minRating: 3,
                    readOnly: readOnly,
                    emptyColor: emptyColor,
                    activeColor: activeColor,
                    hoverColor: activeColor,
                    ratedColor: activeColor,
                    initialRating: input.val(),
                    strokeWidth: 0,
                    useGradient: false,
                    callback: function (currentRating, $el) {
                        // make a server call here
                        input.attr('value', currentRating)
                        input.trigger('change')
                    }
                });
            })
        }


        var setTotals = function () {
            setSegmentedEntry();
            totalNightsOccupied();
            totalRoomRevenue();
            calcOccupancy();
            calcAdr();
            calcRevpar();
            $('.segment-adr').each(function () {
                calcSegmentAdr($(this), false);
            })
            calcGuestTotal();
            $('[data-property-value]').each(function () {
                modifiedFromProperty($(this));
            });
            setCapacity();
            /*
            $('[data-copy-value]').each(function () {
                copyValue($(this));
            })
             */
            showStars();
            $('.rno-reference').html($('#entry_total_room_nights_occupied').val());
            $('.rev-reference').html($('#entry_total_room_revenue').val())
        }

        setTotals();

        var setEntryTitle = function () {
            var title = $("#entry_property_id option:selected").text() + ' ' + $('#entry_period').val();
            $('.entry-title').html(title);
        }

        var checkErrorFree = function () {
            var errs = $('#entry-form').find('.field_with_errors')
            if (errs.length > 0) {
                // bring first error into view
                var firstErr = errs.first();
                var section = firstErr.closest('fieldset');
                var id = section.attr('id');
                var tab = $('#' + id + '-tab');
                tab.click();
                return false
            } else {
                return true
            }
        }

        var isDirty = false;

        var submitEntryFormByAjax = function () {
            if (isDirty) {
                 var that = $('.user-entry-form');
                if (that.length > 0) {
                    var data = that.serialize();
                    $.ajax({
                        url: that.attr('action'),
                        method: that.attr('method'),
                        dataType: 'json',
                        data: data,
                        success: function (data) {
                            console.log('data submmited', new Date().getTime())
                            isDirty = false
                        },
                        error: function (xhr, err) {
                            console.log('err submitting entry form', err)
                        }
                    })
                }
            }
        }

        var required = function (el) {
            if (el.val() == '') {
                return setError(el, 'is required')
            } else {
                return unsetError(el);
            }
        }

        var mustEqual = function (el) {
            var str = el.val();
            if (str == '') {
                return unsetError(el);
            }
            var val = parseFloat(str);
            var compEl = $(el.attr('data-blur-comparison'));
            var comp = parseFloat(compEl.val());
            if (val != comp) {
                return setError(el, 'Must be equal to ' + $('label[for="' + compEl.attr('id') + '"]').text());
            } else {
                return unsetError(el);
            }
        }

        var lessThanOrEqualTo = function (el) {
            var val = parseFloat(el.val());
            var compEl = $(el.attr('data-blur-comparison'));
            var comp = parseFloat(compEl.val());
            if (val > comp) {
                return setError(el, 'Must be less than or equal to ' + $('label[for="' + compEl.attr('id') + '"]').text());
            } else {
                return unsetError(el);
            }
        }

        var noIncomeCommentsRequired = function (el) {
            calcSegmentAdr(el, false)
        }


        var blurCheck = function (el) {
            var checks = el.attr('data-blur-check').split('|');
            for (var i = 0; i < checks.length; i++) {
                var check = checks[i];
                switch (check) {
                    case 'required':
                        if (!required(el)) return;
                        break;
                    case 'mustEqual':
                        if (!mustEqual(el)) return;
                        break;
                    case 'lessThanOrEqualTo':
                        if (!lessThanOrEqualTo(el)) return;
                        break;
                    case 'noIncomeCommentsRequired':
                        if (!noIncomeCommentsRequired(el)) return;
                        break;
                    default:
                        console.log('unknown check', check);
                }
            }
        }

        var runBlurChecks = function () {
            $('[data-blur-check]').each(function () {
                var that = $(this);
                blurCheck(that)
            })
        }

        var dbSubmit = GLOBALS.debounce(function () {
            submitEntryFormByAjax();
        }, 1000)

        $(document).offOn('change', '#entry_property_id, #entry_period_id', function () {
            setEntryTitle();
        }).offOn('change', '.user-entry-form', function (e) {
            isDirty = true;
            $('#unsubmitted_changes').val('true');
            dbSubmit();
        }).offOn('submit', '#entries-form', function () {
            var notDraft = $('#entry_draft').val() == 'false';
            if (notDraft) {
                runBlurChecks();
                return checkErrorFree();
            } else {
                return true
            }
        });

        $(window).offOn('unload', function () {
            submitEntryFormByAjax()
        })


        $(document).offOn('period-selected', '#periods-show, #entries-edit', function (event, periodId) {
            console.log('got period select event', periodId)
            // update property hrefs with new period id
            var selected = null;
            var links = $('.enterable-property-list a')
            links.each(function () {
                var that = $(this);
                var upId = that.attr('data-property-id');
                var newHref = "/periods/" + periodId + "/entries/" + upId + "/edit";
                var href = that.attr('href', newHref);
                if (that.hasClass('selected')) selected = that;
            });
            var pid = selected.attr('data-property-id')
            $('#property-list').load('/periods/' + periodId, function () {
                var selected = $('[data-property-id="' + pid + '"]')
                selected.click()
            })
            /*
                        setTimeout(function () {
                            console.log('selected href', selected.attr('href'))
                            // Rails.fire(selected[0], 'click'); // its a data remote so we need to trigger via rails not standard click
                            selected.click()
                        }, 100);

             */
            return false;
        }).offOn('click', '.enterable-property-list .region-link', function () {
            var that = $(this);
            $('.enterable-property-list .region-link').removeClass('selected');
            that.addClass('selected')
            var loc = new URL(that[0].href);
            loc.hash = document.location.hash
            submitEntryFormByAjax()
            GLOBALS.pushUrl(loc.href);
            console.log('entry nav clicked')
            return false;
        }).offOn('click', '.entry-nav-link', function () {
            var that = $(this);
            console.log('that is', that[0])
            //$('.entry-section-link').removeClass('selected');
            //that.addClass('selected')
            var loc = new URL(document.location.href);
            loc.hash = that.attr('href')
            submitEntryFormByAjax()
            GLOBALS.pushUrl(loc.href);
            return false;
        }).offOn('click', '#revert-entry', function () {
            var that = $(this);
            $('#entry-validation').find('[data-previous-value]').each(function () {
                var that = $(this);
                var input = that.closest('.field').find('input');
                input.val(that.text());
                that.parent().remove(); // could swap previoius input value here
            });
            that.hide();  // once form reverted can't be re-reverted
            return false;
        }).offOn('blur', '[data-blur-check]', function () {
            var that = $(this);
            blurCheck(that)
        }).offOn('error-set', '[data-blur-check="required"]', function () {
            var that = $(this);
            that.attr('placeholder', 'required')
        }).offOn('error-unset', '[data-blur-check="required"]', function () {
            var that = $(this);
            that.removeAttr('placeholder')
        })


    }
);
