$(document).offOn(GLOBALS.readyEvent, function () {
    if ($('#regions-edit').length > 0) {

    }


});

$(document).offOn('click', '#region-add-rating', function () {
    console.log('clicked add rating');
    var rating = $('#rating-template').children().first().clone();
    var inputs = rating.find('input');
    var unique = new Date().getTime()
    inputs.each(function () {
        var that = $(this);
        var name = that.attr('name')
        var id = that.attr('id')
        name = name.replace('[rating]', '[ratings_attributes][' + unique + ']');
        that.attr('name', name);
        if (id) {
            id = id.replace('rating', 'ratings_attributes_' + unique);
            that.attr(id, id)
        }
    });
    $('#region-ratings').append(rating);
    return false;
})
